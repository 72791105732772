export default [
  {
    path: '/',
    name: 'home',
    meta: { auth: true, guest: false, permissions: [] },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'auth.login',
    meta: { auth: false, guest: true, permissions: [] },
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/password/forgot',
    name: 'auth.password.forgot',
    meta: { auth: false, guest: true, permissions: [] },
    component: () => import('../views/auth/ForgotPassword.vue')
  },
  {
    path: '/password/reset',
    name: 'auth.password.reset',
    meta: { auth: false, guest: true, permissions: [] },
    component: () => import('../views/auth/ResetPassword.vue')
  },
  {
    path: '/forms',
    name: 'forms.index',
    meta: { auth: true, guest: false, permissions: ['view_form', 'view_partner'] },
    component: () => import('../views/forms/Index.vue')
  },
  {
    path: '/forms/create',
    name: 'forms.create',
    meta: { auth: true, guest: false, permissions: ['view_form', 'create_form', 'view_partner', 'view_program-group'] },
    component: () => import('../views/forms/Create.vue')
  },
  {
    path: '/forms/:formId/clone',
    name: 'forms.clone',
    meta: { auth: true, guest: false, permissions: ['view_form', 'create_form', 'view_partner', 'view_program-group'] },
    component: () => import('../views/forms/Clone.vue')
  },
  {
    path: '/forms/:formId/edit',
    name: 'forms.edit',
    meta: { auth: true, guest: false, permissions: ['view_form', 'update_form', 'view_partner', 'view_program-group'] },
    component: () => import('../views/forms/Edit.vue')
  },
  {
    path: '/forms/:formId/view',
    name: 'forms.view',
    meta: { auth: true, guest: false, permissions: ['view_form', 'view_partner', 'view_program-group'] },
    component: () => import('../views/forms/Edit.vue')
  },
  {
    path: '/programs',
    name: 'programs.index',
    meta: { auth: true, guest: false, permissions: ['view_program'] },
    component: () => import('../views/programs/Index.vue')
  },
  {
    path: '/programs/:programId',
    name: 'programs.show',
    meta: { auth: true, guest: false, permissions: ['view_program'] },
    component: () => import('../views/programs/Show.vue')
  },
  {
    path: '/program-groups',
    name: 'program-groups.index',
    meta: { auth: true, guest: false, permissions: ['view_program-group', 'view_partner'] },
    component: () => import('../views/program-groups/Index.vue')
  },
  {
    path: '/program-groups/:programGroupId',
    name: 'program-groups.show',
    meta: { auth: true, guest: false, permissions: ['view_program-group'] },
    component: () => import('../views/program-groups/Show.vue')
  },
  {
    path: '/program-qualifiers',
    name: 'program-qualifiers.index',
    meta: { auth: true, guest: false, permissions: ['view_program-qualifier', 'view_partner'] },
    component: () => import('../views/program-qualifiers/Index.vue')
  },
  {
    path: '/program-qualifiers/:programQualifierId',
    name: 'program-qualifiers.show',
    meta: { auth: true, guest: false, permissions: ['view_program-qualifier'] },
    component: () => import('../views/program-qualifiers/Show.vue')
  },
  {
    path: '/users',
    name: 'users.index',
    meta: { auth: true, guest: false, permissions: ['view_user'] },
    component: () => import('../views/users/Index.vue')
  },
  {
    path: '/ad-tables',
    name: 'ad-tables.index',
    meta: { auth: true, guest: false, permissions: ['view_ad-table'] },
    component: () => import('../views/ad-tables/Index.vue')
  },
  {
    path: '/ad-tables/create',
    name: 'ad-tables.create',
    meta: { auth: true, guest: false, permissions: ['view_ad-table', 'create_ad-table', 'view_partner', 'view_program-group']  },
    component: () => import('../views/ad-tables/Create.vue')
  },
  {
    path: '/ad-tables/:adTableId/edit',
    name: 'ad-tables.edit',
    meta: { auth: true, guest: false, permissions: ['view_ad-table', 'update_ad-table', 'view_partner', 'view_program-group']  },
    component: () => import('../views/ad-tables/Edit.vue')
  },
  {
    path: '/form-embeds',
    name: 'form-embeds.index',
    meta: { auth: true, guest: false, permissions: ['view_form'] },
    component: () => import('../views/form-embeds/Index.vue')
  },
  {
    path: '/form-embeds/:embedId',
    name: 'form-embeds.show',
    meta: { auth: true, guest: false, permissions: ['view_form'] },
    component: () => import('../views/form-embeds/Show.vue')
  },
  {
    path: '/partner-accounts',
    name: 'partner-accounts.index',
    meta: { auth: true, guest: false, permissions: ['view_form'] },
    component: () => import('../views/partner-accounts/Index.vue')
  },
  {
    path: '/partner-accounts/:partnerId',
    name: 'partner-accounts.show',
    meta: { auth: true, guest: false, permissions: ['view_form'] },
    component: () => import('../views/partner-accounts/Show.vue')
  },
  {
    path: '/program-content',
    name: 'program-content.index',
    meta: { auth: true, guest: false, permissions: ['view_program-qualifier', 'view_partner'] },
    component: () => import('../views/program-contents/Index.vue')
  },
  {
    path: '/program-content/:programContentId',
    name: 'program-content.show',
    meta: { auth: true, guest: false, permissions: ['view_program-qualifier'] },
    component: () => import('../views/program-contents/Show.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/misc/404.vue')
  }
]