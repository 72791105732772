import base from './base'
import domestic from './domestic'
import international from './international'
import zipcodeDomestic from './zipcode-domestic'
import embed from './embed'

export default {
  base,
  domestic,
  international,
  zipcodeDomestic,
  embed
}

export {
  base,
  domestic,
  international,
  zipcodeDomestic,
  embed
}