<template>
  <b-navbar
    class="bg-light px-3"
    toggleable="lg"
  >
    <router-link
      class="navbar-brand"
      to="/"
    >
      <img
        src="../assets/icons/freya-logo-glyph.svg"
        alt="Freya logo"
        width="35px"
        height="35px"
      >
    </router-link>
    <b-navbar-toggle target="nav-collapse" />
    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav class="me-auto">
        <router-link
          v-if="hasRouteAccess('forms.index')" 
          class="nav-link"
          :to="{ name: 'forms.index' }"
        >
          Forms
        </router-link>
        <router-link
          v-if="hasRouteAccess('forms.index')" 
          class="nav-link"
          :to="{ name: 'form-embeds.index' }"
        >
          Form Embeds
        </router-link>
        <router-link
          v-if="hasRouteAccess('programs.index')" 
          class="nav-link" 
          :to="{ name: 'programs.index' }"
        >
          Programs
        </router-link>
        <router-link
          v-if="hasRouteAccess('program-groups.index')" 
          class="nav-link" 
          :to="{ name: 'program-groups.index' }"
        >
          Program Groups
        </router-link>
        <router-link
          v-if="hasRouteAccess('program-qualifiers.index')" 
          class="nav-link" 
          :to="{ name: 'program-qualifiers.index' }"
        >
          Program Qualifiers
        </router-link>
        <router-link
          v-if="hasRouteAccess('program-content.index')" 
          class="nav-link" 
          :to="{ name: 'program-content.index' }"
        >
          Program Content
        </router-link>
        <router-link
          v-if="hasRouteAccess('partner-accounts.index')" 
          class="nav-link" 
          :to="{ name: 'partner-accounts.index' }"
        >
          Partner Accounts
        </router-link>
        <router-link
          v-if="hasRouteAccess('users.index')" 
          class="nav-link" 
          :to="{ name: 'users.index' }"
        >
          Users
        </router-link>
      </b-navbar-nav>
      <b-navbar-nav>
        <router-link
          v-if="hasRouteAccess('auth.login')"
          class="nav-link"
          :to="{ name: 'auth.login' }"
        >
          Login
        </router-link>
        <span
          v-if="isAuthenticated"
          class="nav-link"
          @click="logout()"
        >
          Logout
        </span>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      // 
    }
  },

  computed: {
    /** 
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      isAuthenticated: 'auth/getAuthenticated',
      hasRouteAccess: 'auth/hasRouteAccess'
    })
  },
  
  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      logout: 'auth/logout'
    })
  }
}
</script>

<style lang="scss">
.nav-link:hover {
  cursor: pointer;
}
</style>