export default {
  id: 'program-content',
  component: 'fr-program-content',
  meta: {
    rules: [],
    classes: [],
  },
  admin: {
    name: 'Program Content',
    mandatory: false,
    required: false,
    required_always: false,
    required_never: false,
    dynamic: false,
    dynamicDisplay: false,
    dynamicLabel: false,
    conditionalLogicNotComplete: false,
  }
}