<template>
  <option
    :value="value"
    :disabled="disabled"
  >
    <slot />
  </option>
</template>
  
  <script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      value: {
        type: [String, Number, Boolean, Object, Array],
        default: null
      },
    }
  }
  </script>
  